

(function($) {


  /*
  var msnry;
  
  function initializeMasonry() {
    // Destroy existing Masonry instance to avoid duplicates
    if (msnry) {
      msnry.destroy();
    }
  
    // Initialize Masonry
    msnry = new Masonry('.facetwp-template', {
      itemSelector: '.tease',
      columnWidth: '.tease', // Use the item width as the column width
      percentPosition: true, // Use percentage widths
      gutter: 18, // Add horizontal spacing between items
      transitionDuration: 0, // Disable transitions to avoid flickering
            
    });
  }
  */

jQuery( document ).ready( function() {



  // Initialize on page load
    //initializeMasonry();
    initAccordion(); // Initialize accordion

  // Re-initialize after FacetWP updates the content
  $(document).on('facetwp-loaded', function() {
   // initializeMasonry();
    initAccordion(); // Re-initialize accordion
  });





   // if i click on .hamburger, show nav#nav-mobile:
    $( '.hamburger' ).on( 'click', function() {
      $( '#nav-mobile' ).show();
      $( 'body, html' ).css( 'overflow', 'hidden' );
  });
  
  $( '.xcontainer' ).on( 'click', function() {
      $( '#nav-mobile' ).hide();
      $( 'body, html' ).css( 'overflow', 'auto' );
  });



  if($('.anmeldebutton').length) {
    //$('.kurswertinput > input').attr('disabled', 'disabled');
    $('.anmeldebutton').each(function(e) {
      if($(this).data('gesperrt') == 'gesperrt') {
        // prevent default action
        $(this).on('click', function(e) {
          e.preventDefault();
        });
        $(this).css(('cursor', 'not-allowed'));
        $(this).addClass('disabled');
        $(this).attr('disabled', 'disabled');
        // change text to 'Anmeldung geschllossen"
        $(this).text('Geschlossen');
        // add popover with message: Um sich anzumelden, melden Sie sich bitte bei uns.
        $(this).popover({
          content: 'Um sich anzumelden, melden Sie sich bitte bei uns via info@santh.ch.',
          placement: 'bottom',
          trigger: 'hover',
          html: true,
          delay: { "show": 0, "hide": 0 },
          container: 'body',
          template: `
            <div class="popover custom-popover" role="tooltip">
              <div class="arrow"></div>
              <h3 class="popover-header"></h3>
              <div class="popover-body"></div>
            </div>
          `
        });
        
        
        } else {
          $(this).on('click', function() {
            $('.kurswertinput > input').val($(this).data('kurstitel'));
          });

        }

    });
  }
  
    $('#nav-mobile').on('click', 'a[href*="#"]', function(event) {
        var linkHref = $(this).attr('href');

        // Create a temporary anchor element to resolve the full URL
        var linkElement = document.createElement('a');
        linkElement.href = linkHref;

        // Get the link's URL without the hash part
        var linkUrlWithoutHash = linkElement.protocol + '//' + linkElement.host + linkElement.pathname;

        // Get the current page's URL without the hash part
        var currentUrlWithoutHash = window.location.protocol + '//' + window.location.host + window.location.pathname;

        // Compare the URLs
        if (linkUrlWithoutHash === currentUrlWithoutHash) {
            // Close the menu
            $('#nav-mobile').hide();
            $('body, html').css('overflow', 'auto');

        }
    });


  
  });

// make header.header sticky after scrolling one screen height
$(window).on('scroll', function() {
  if ($(window).scrollTop() > 260) {
    // if the whole body is more than two screen heights tall, use this:
   if ($('body').height() > 1.3 * $(window).height()) {
    $('header.header').addClass('sticky');
    if($('.facetselectors').length) {
      $('.facetselectors').addClass('sticky');
      $('.content-wrapper.facetwp-template').addClass('stickytop');
    }
   }
  } else {
    $('header.header').removeClass('sticky');
    if($('.facetselectors').length) {
      $('.facetselectors').removeClass('sticky');
      $('.content-wrapper.facetwp-template').removeClass('stickytop');
    }
  }
});

// Function to initialize the accordion event listeners
function initAccordion() {
  var accordionHeaders = document.querySelectorAll('.accordion');

  accordionHeaders.forEach(function (header) {
      // Remove existing click listener to prevent duplicate bindings
      header.removeEventListener('click', toggleAccordion);
      // Add click listener to toggle 'active' class
      header.addEventListener('click', toggleAccordion);
  });
}

// Function to toggle the 'active' class
function toggleAccordion(event) {
  var header = event.currentTarget;
  var accordionItem = header.closest('.accordion');

  accordionItem.classList.toggle('active');

  // Optional: Adjust the height of the content for smooth transitions
  var accordionContent = accordionItem.querySelector('.acccontent');
  if (accordionContent) {
    if (accordionItem.classList.contains('active')) {
      accordionContent.style.maxHeight = accordionContent.scrollHeight + 'px';
    } else {
      accordionContent.style.maxHeight = null;
    }
  }


}

// Initialize on page load
document.addEventListener('DOMContentLoaded', function () {
  initAccordion();
});

// Re-initialize after FacetWP updates the content
document.addEventListener('facetwp-loaded', function () {
  initAccordion();
});




})(jQuery);
